
interface IPostalAddress
{
    LineOne: string;
    LineTwo: string | null;
    Town: string;
    County: string;
    PostalCode: string;
};

export type PropertyInformation = {
    Url: string;
    ImageUrl: string;
    Name: string;
    Bedrooms: number;
    Address: IPostalAddress;
};

export type TimeBlockItem = {
    start: string;
    end: string;
}

export type ArrangeBookingState = {
    type: string | null;
    bedrooms: number | null;
    date: Date | null;
    time: TimeBlockItem | null;
    additionalProperties: PropertyInformation[];
};

export function formatDate(date: Date | null | undefined)
{
    if (!date)
    {
        return "";
    }

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""}${day}`
}