interface IPostalAddress
{
    lineOne: string;
    lineTwo: string | null;
    town: string;
    county: string;
    postalCode: string;
}

interface IOfficeMapPin
{
    name: string;
    url: string;

    address: IPostalAddress;
    phone: string;

    latitude: number;
    longitude: number;

    imageFilename?: string;
    pinImageFilename?: string;
}

import { Cluster, ClusterStats, MarkerClusterer, Renderer } from "@googlemaps/markerclusterer";
import * as $ from "jquery";

declare global
{
    interface Window
    {
        InitializeMapView: () => void;
    }
}

class CloudLettingsRenderer implements Renderer
{
    render({ count, position }: Cluster, _stats: ClusterStats): google.maps.Marker
    {
        const svg = window.btoa(`
  <svg fill="#173c73" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
    <circle cx="120" cy="120" opacity="1" r="70" />
    <circle cx="120" cy="120" opacity=".6667" r="90" />
    <circle cx="120" cy="120" opacity=".3333" r="110" />
  </svg>`);

        // create marker using svg icon
        return new google.maps.Marker({
            position,
            icon: {
                url: `data:image/svg+xml;base64,${svg}`,
                scaledSize: new google.maps.Size(45, 45),
            },
            label: {
                text: String(count),
                color: "#fff",
                fontSize: "12px",
            },
            title: `Cluster of ${count} markers`,
            // adjust zIndex to be above other markers
            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
        });
    }

}

let map: google.maps.Map;
let infoWindow: google.maps.InfoWindow;
let initialZoom: boolean;

let mapElement: HTMLElement | null = null;
let searchElement: HTMLElement | null = null;
let directionsElement: HTMLElement | null = null;
let errorElement: HTMLElement | null = null;

let placeMarkers = new Array<google.maps.Marker>();
let directionsService: google.maps.DirectionsService;
let directionsDisplay: google.maps.DirectionsRenderer;

let mapStyle: any;

function toggleMarker(placemark: IOfficeMapPin, marker: google.maps.Marker)
{
    if (infoWindow != null)
    {
        infoWindow.close();
    }

    let infoContent = `<div class=\"gmaps-info-window\">`;
    if (placemark.url)
    {
        infoContent += `<a href="${placemark.url}">`;
    }
    if (placemark.imageFilename)
    {
        infoContent += `<img src="${placemark.imageFilename}" alt="" />`;
    }
    infoContent += `<h4>${placemark.name}</h4><p>${placemark.address.lineOne}`;
    if (placemark.address.lineTwo)
    {
        infoContent += `<br />${placemark.address.lineTwo}`;
    }
    if (placemark.address.town)
    {
        infoContent += `<br />${placemark.address.town}`;
    }
    if (placemark.address.county)
    {
        infoContent += `<br />${placemark.address.county}`;
    }
    if (placemark.address.postalCode)
    {
        infoContent += `<br />${placemark.address.postalCode}`;
    }
    infoContent += '</p>';
    if (placemark.url)
    {
        infoContent += '</a>';
    }
    infoContent += '</div>';
    infoWindow = new google.maps.InfoWindow({
        content: infoContent
    });

    infoWindow.open(map, marker);
}

function loadTheme(data: JQueryXHR): void
{
    mapStyle = data.responseJSON;

    if (mapElement == null)
    {
        return;
    }

    let apiPath = mapElement.dataset.apiPath;
    if (apiPath != null)
    {
        $.ajax({
            url: apiPath,
            complete: loadXmlDocument
        });
    }
}

function loadXmlDocument(data: JQueryXHR): void
{
    if (mapElement == null)
    {
        return;
    }

    const placemarks = JSON.parse(data.responseText) as IOfficeMapPin[];

    map = new google.maps.Map(mapElement,
        {
            zoom: 16,
            center: new google.maps.LatLng(0, 0),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: mapStyle
        });

    for (let i = 0; i < placemarks.length; i++)
    {
        const placemark = placemarks[i];
        const point = new google.maps.LatLng(placemark.latitude, placemark.longitude);

        const marker = new google.maps.Marker({
            map: map,
            position: point,
            title: placemark.name,
            icon: placemark.pinImageFilename ?? "/site/img/icons/map-pin.svg"
        });

        google.maps.event.addListener(marker,
            "click",
            function (this: google.maps.Marker)
            {
                toggleMarker(placemark, this);
            });

        if (placemarks.length === 1 && mapElement.dataset.nopopup !== "true")
        {
            toggleMarker(placemark, marker);
        }

        placeMarkers.push(marker);
    }

    new MarkerClusterer({ markers: placeMarkers, map, renderer: new CloudLettingsRenderer });

    findMapCenterAndZoom();
    setupDirections();
}

function findMapCenterAndZoom(): void
{
    if (map == null)
    {
        return;
    }

    const bounds = new google.maps.LatLngBounds();
    placeMarkers.forEach((value: google.maps.Marker) =>
    {
        let point = value.getPosition();
        if (point != null)
        {
            bounds.extend(point);
        }
    });

    google.maps.event.addListener(map,
        "zoom_changed",
        () =>
        {
            const zoomChangeBoundsListener = google.maps.event.addListener(map,
                "bounds_changed",
                function (this: google.maps.Map)
                {
                    const currentZoom = this.getZoom();
                    if (currentZoom && currentZoom > 15 && initialZoom)
                    {
                        // Change max/min zoom here
                        this.setZoom(15);
                        initialZoom = false;
                    }
                    google.maps.event.removeListener(zoomChangeBoundsListener);
                });
        });

    const parentTab = document.querySelector("a[href='#search-map']");
    if (parentTab)
    {
        parentTab.addEventListener("shown.bs.tab", () =>
        {
            console.log("tab switch");
            const bounds = new google.maps.LatLngBounds();
            placeMarkers.forEach((value: google.maps.Marker) =>
            {
                let point = value.getPosition();
                if (point != null)
                {
                    bounds.extend(point);
                }
            });
            initialZoom = true;
            map.fitBounds(bounds);
        });
    }

    initialZoom = true;
    map.fitBounds(bounds);
}

function findDirectionsKeyDown(ev: KeyboardEvent)
{
    if (ev.key === "Enter")
    {
        ev.preventDefault();
        findDirections(ev);
    }
}

function findDirections(ev: Event)
{
    if (ev.currentTarget == null)
    {
        return;
    }

    let rootElement = ev.currentTarget as HTMLElement;
    while (rootElement.attributes.getNamedItem("map-search") == null)
    {
        if (rootElement.parentElement == null)
        {
            return;
        }

        rootElement = rootElement.parentElement;
    }

    const inputElem = rootElement.querySelector("input");
    if (inputElem == null)
    {
        return;
    }

    const fromAddress = inputElem.value;

    if (directionsElement == null)
    {
        return;
    }

    directionsDisplay.setMap(map);
    directionsDisplay.setPanel(directionsElement);

    // get first placemark position
    let destination: google.maps.LatLng | null | undefined = null;

    if (placeMarkers.length > 1)
    {
        const locationSelect = searchElement?.querySelector("select");
        if (locationSelect != null)
        {
            destination = placeMarkers[parseInt(locationSelect.value)].getPosition();
        }
    }
    else
    {
        destination = placeMarkers[0].getPosition();
    }

    if (destination == null)
    {
        return;
    }

    directionsService.route({
        origin: fromAddress,
        destination: destination,
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.IMPERIAL,
        provideRouteAlternatives: true
    }, displayDirections);
}

function displayDirections(result: google.maps.DirectionsResult | null, status: google.maps.DirectionsStatus)
{
    if (directionsElement == null || errorElement == null || result == null)
    {
        return;
    }

    if (status === google.maps.DirectionsStatus.OK)
    {
        directionsElement.style.display = "block";
        errorElement.style.display = "none";
        directionsDisplay.setDirections(result);
    }
    else
    {
        directionsElement.style.display = "none";
        errorElement.style.display = "block";
    }
}

function setupDirections()
{
    if (searchElement == null)
    {
        return;
    }

    const group = document.createElement("div");
    group.className = "input-group";

    const destination = document.createElement("input");
    destination.type = "text";
    destination.autocomplete = "postal-code";
    destination.placeholder = "Enter your postcode...";
    destination.className = "form-control";
    destination.addEventListener("keydown", findDirectionsKeyDown);
    group.appendChild(destination);

    const button = document.createElement("button");
    button.type = "button";
    button.className = "btn btn-dark";
    button.addEventListener("click", (ev) => findDirections(ev));

    const icon = document.createElement("i");
    icon.className = "la la-angle-right";

    button.appendChild(icon);
    group.appendChild(button);
    searchElement.appendChild(group);

    if (placeMarkers.length > 1)
    {
        const select = document.createElement("select");
        select.className = "form-control mb-3";

        for (let i = 0; i < placeMarkers.length; i++)
        {
            const label = placeMarkers[i].getTitle();
            if (label == null)
            {
                continue;
            }

            const option = document.createElement("option");
            option.value = i.toFixed(0);
            option.text = label;

            select.appendChild(option);
        }

        select.addEventListener("change", (ev) => { if (destination.value != "") findDirections(ev); });

        searchElement.prepend(select);
    }

    errorElement = document.createElement("div");
    errorElement.className = "alert alert-danger mt-3";
    errorElement.style.display = "none";
    errorElement.innerText = "There was an error searching for the specified location.";
    searchElement.appendChild(errorElement);

    directionsService = new google.maps.DirectionsService();
    directionsDisplay = new google.maps.DirectionsRenderer();
}

function Initialize()
{
    let elem = document.querySelector("div[map-element]");
    if (elem != null && (elem instanceof HTMLElement))
    {
        mapElement = elem;

        const themePath = mapElement.dataset.theme ?? "/site/map/theme.json";
        $.ajax({
            url: themePath,
            complete: loadTheme
        });
    }

    elem = document.querySelector("div[map-search]");
    if (elem != null && (elem instanceof HTMLElement))
    {
        searchElement = elem;
    }

    elem = document.querySelector("div[map-directions]");
    if (elem != null && (elem instanceof HTMLElement))
    {
        directionsElement = elem;
    }
}

window.InitializeMapView = Initialize;