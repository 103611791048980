import { SetValidationValues } from './_helpers';

$.validator.setDefaults({
    ignore: ""
});

$.validator.addMethod("mustbetrue",
    function (_value, element, _params)
    {
        return (element as HTMLInputElement).checked;
    });

$.validator.unobtrusive.adapters.add("mustbetrue",
    (options: any) =>
    {
        SetValidationValues(options, "mustbetrue", true);
    });