import * as React from "react";
import { useState } from "react";


type WaterCalculatorState = {
    tenants: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | undefined;
    opening: number | undefined;
    current: number | undefined;
};

const QuotaTable = {
    1: 125,
    2: 130,
    3: 162,
    4: 190,
    5: 215,
    6: 240,
    7: 270,
    8: 290,
    9: 310,
    10: 330
}

const WaterCalculatorStep: React.FunctionComponent = () =>
{
    const [data, setData] = useState<WaterCalculatorState>({
        tenants: undefined,
        current: undefined,
        opening: undefined
    });

    function updateTenants(ev: React.ChangeEvent<HTMLSelectElement>)
    {
        const result = parseInt(ev.target.value);
        setData({
            tenants: result > 0 && result <= 10 ? result as (1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10) : undefined,
            opening: data.opening,
            current: data.current
        });
    }

    function updateOpening(ev: React.ChangeEvent<HTMLInputElement>)
    {
        const result = parseInt(ev.target.value);
        setData({
            tenants: data.tenants,
            opening: result > 0 ? result : undefined,
            current: data.current
        });
    }

    function updateCurrent(ev: React.ChangeEvent<HTMLInputElement>)
    {
        const result = parseInt(ev.target.value);
        setData({
            tenants: data.tenants,
            opening: data.opening,
            current: result > 0 ? result : undefined
        });
    }

    const quota = data.tenants ? QuotaTable[data.tenants] : 0;
    let usage = data.opening && data.current ? data.current - data.opening : 0;

    return (
        <section className="form-contact">
            <div className="bedroom-select">
                <h4>Water</h4>
                <p>Your allowance as allocated in the tenancy agreement is set out in m3. If you do not have access to a water meter, please contact the office and we will help you further.</p>
            </div>
            <form>
                <div className="fields">
                    <div className="form-group">
                        <label>Number of tenants *</label>
                        <div className="input-control">
                            <select value={data.tenants} onChange={updateTenants}>
                                <option value="" key={null}>Please select...</option>
                                {[...Array(10).keys()].map((i) => <option value={i + 1} key={i + 1}>{i + 1}</option>)}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="fields cols-2">
                    <div className="form-group">
                        <label>Opening reading (m3) *</label>
                        <div className="input-control">
                            <input type="number" required={true} min="0" step="any" value={data.opening} onChange={updateOpening} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Current reading (m3) *</label>
                        <div className="input-control">
                            <input type="number" required={true} min="0" step="any" value={data.current} onChange={updateCurrent} />
                        </div>
                        {
                            data.opening && data.current && data.opening >= data.current
                                ? <span className="field-validation field-validation-error">The current reading should be higher than the opening reading.</span>
                                : null
                        }
                    </div>
                </div>
            </form>
            {
                data.tenants
                && data.opening
                && data.current
                && (data.opening < data.current)
                    ?
                    <div className="bedroom-select">
                        <p className="quota-percentage">
                            <strong>
                                {(usage / quota).toLocaleString("en-GB", { style: "percent", maximumFractionDigits: 1 })}
                            </strong> of allowance used
                        </p>
                        <div className="fields cols-2 quota-summary">
                            <div className="form-group">
                                <label>Usage since opening reading:</label>
                                <p className="quota-value">{usage.toLocaleString("en-GB", { maximumFractionDigits: 2 })} m3</p>
                            </div>
                            <div className="form-group">
                                <label>Total allowance:</label>
                                <p className="quota-value">{quota} m3</p>
                            </div>
                        </div>
                    </div>
                    : null
            }
        </section>
    );
}

export default WaterCalculatorStep;