import * as React from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { PropertyInformation } from "./types";

const SelectTypeStep: React.FunctionComponent<{ property: PropertyInformation, updateType: (type: string) => void } & Partial<StepWizardChildProps>> = (props) =>
{
    function changeType(type: string)
    {
        props.updateType(type);
        if (props.nextStep)
        {
            props.nextStep();
        }
    }

    return (
        <section className="flex-columns">
            <label onClick={() => changeType("this-property")}>
                <div className="column">
                    <div className="column-image">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 207.946 195.677">
                            <g transform="translate(0 -18.661)">
                                <path d="M116.039,125.624a66.906,66.906,0,0,1,11.571-13.371H102.937a13.384,13.384,0,0,0-13.37,13.371v53.483H49.454V85.926l66.854-52.079,66.854,52.146V96.581a65.815,65.815,0,0,1,13.371,3.852V96.409l11.746,9.159,8.31-10.51L120.4,20.088a6.756,6.756,0,0,0-8.3,0L16.027,95l8.31,10.51,11.746-9.159v82.758a13.4,13.4,0,0,0,13.371,13.371h63.2a65.922,65.922,0,0,1-5.205-13.371h-4.508V125.624Z" transform="translate(-16.027)" fill="#173c73" />
                                <g transform="translate(103.156 109.549)">
                                    <path d="M171.578,109.549a52.394,52.394,0,1,0,52.4,52.395A52.395,52.395,0,0,0,171.578,109.549Zm-5.008,72.437-23.389-23.378,6.681-6.691,16.708,16.708L193.293,141.9l6.681,6.681Z" transform="translate(-119.183 -109.549)" fill="#66cceb" />
                                </g>
                            </g>
                        </svg>

                    </div>
                    < div className="column-heading">
                        <h4>View this property</h4>
                    </div>
                    < div className="column-text">
                        <p>
                            <strong>{props.property.Name}</strong><br />
                            <small>{props.property.Address.LineOne}, {props.property.Address.Town} {props.property.Address.PostalCode}</small>
                        </p>
                    </div>
                </div>
            </label>
            < label onClick={() => changeType("this-property-and-similar")}>
                <div className="column">
                    <div className="column-image">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 207.946 195.677">
                            <g transform="translate(0 -18.661)">
                                <path d="M116.039,125.624a66.906,66.906,0,0,1,11.571-13.371H102.937a13.384,13.384,0,0,0-13.37,13.371v53.483H49.454V85.926l66.854-52.079,66.854,52.146V96.581a65.815,65.815,0,0,1,13.371,3.852V96.409l11.746,9.159,8.31-10.51L120.4,20.088a6.756,6.756,0,0,0-8.3,0L16.027,95l8.31,10.51,11.746-9.159v82.758a13.4,13.4,0,0,0,13.371,13.371h63.2a65.922,65.922,0,0,1-5.205-13.371h-4.508V125.624Z" transform="translate(-16.027)" fill="#173c73" />
                                <path d="M171.578,109.549a52.394,52.394,0,1,0,52.4,52.395A52.395,52.395,0,0,0,171.578,109.549ZM194.893,166.8H177.071v18.8H166.182V166.8H148.263V156.548h17.919V138.286h10.889v18.262h17.822Z" transform="translate(-16.027)" fill="#66cceb" />
                            </g>
                        </svg>

                    </div>
                    < div className="column-heading">
                        <h4>View this property &amp; similar properties ?</h4>
                    </div>
                </div>
            </label>
            < label onClick={() => changeType("help-me-choose")}>
                <div className="column">
                    <div className="column-image">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 207.946 195.677">
                            <g transform="translate(-16.027 -18.661)">
                                <path d="M116.039,125.624a66.906,66.906,0,0,1,11.571-13.371H102.937a13.386,13.386,0,0,0-13.371,13.371v53.483H49.454V85.926l66.854-52.079,66.854,52.146V96.581a65.815,65.815,0,0,1,13.371,3.852V96.409l11.746,9.159,8.31-10.51L120.4,20.088a6.754,6.754,0,0,0-8.3,0L16.027,95l8.31,10.51,11.746-9.159v82.758a13.4,13.4,0,0,0,13.371,13.371h63.2a65.922,65.922,0,0,1-5.205-13.371h-4.508V125.624Z" fill="#173c73" />
                                <path d="M171.578,109.549a52.394,52.394,0,1,0,52.4,52.395A52.395,52.395,0,0,0,171.578,109.549Zm5.708,78.3H164.639v-12.1h12.647ZM188.6,155.279a13.6,13.6,0,0,1-1.025,2.786,10.791,10.791,0,0,1-1.675,2.289,23.413,23.413,0,0,1-1.811,1.795q-.719.615-2.051,1.64-1.3.991-1.931,1.538a17.715,17.715,0,0,0-1.47,1.47,5.8,5.8,0,0,0-1.2,1.914,6.373,6.373,0,0,0-.359,2.188v1.333H164.913V170.9a17.529,17.529,0,0,1,.478-4.221,15.874,15.874,0,0,1,1.077-3.145,10.322,10.322,0,0,1,1.744-2.444,19.6,19.6,0,0,1,1.862-1.811q.718-.58,2.051-1.539,1.128-.819,1.709-1.282a13.006,13.006,0,0,0,1.3-1.213,4.445,4.445,0,0,0,1.008-1.572,5.522,5.522,0,0,0,.29-1.846,4.607,4.607,0,0,0-1.418-3.52,4.876,4.876,0,0,0-3.469-1.333,5.321,5.321,0,0,0-3.657,1.367,4.73,4.73,0,0,0-1.539,3.726H154.112a15.724,15.724,0,0,1,2.393-8.75,14.692,14.692,0,0,1,6.289-5.486,20.748,20.748,0,0,1,8.784-1.795,18.368,18.368,0,0,1,12.526,4.307,14.324,14.324,0,0,1,4.94,11.348A13.964,13.964,0,0,1,188.6,155.279Z" fill="#66cceb" />
                            </g>
                        </svg>
                    </div>
                    < div className="column-heading">
                        <h4>Need help with choosing a property ?</h4>
                    </div>
                </div>
            </label>
        </section>
    );
}

export default SelectTypeStep;