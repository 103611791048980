import * as React from "react";
import { useState } from "react";


type GasCalculatorState = {
    tenants: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | undefined;
    opening: number | undefined;
    current: number | undefined;
};

const QuotaTable = {
    1: 10000,
    2: 13000,
    3: 16000,
    4: 20000,
    5: 25000,
    6: 30000,
    7: 34000,
    8: 38000,
    9: 41000,
    10: 44000
}

const GasCalculatorStep: React.FunctionComponent = () =>
{
    const [data, setData] = useState<GasCalculatorState>({
        tenants: undefined,
        opening: undefined,
        current: undefined
    });

    function updateTenants(ev: React.ChangeEvent<HTMLSelectElement>)
    {
        const result = parseInt(ev.target.value);
        setData({
            tenants: result > 0 && result <= 10 ? result as (1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10) : undefined,
            opening: data.opening,
            current: data.current
        });
    }

    function updateOpening(ev: React.ChangeEvent<HTMLInputElement>)
    {
        const result = parseInt(ev.target.value);
        setData({
            tenants: data.tenants,
            opening: result > 0 ? result : undefined,
            current: data.current
        });
    }

    function updateCurrent(ev: React.ChangeEvent<HTMLInputElement>)
    {
        const result = parseInt(ev.target.value);
        setData({
            tenants: data.tenants,
            opening: data.opening,
            current: result > 0 ? result : undefined
        });
    }

    const quota = data.tenants ? QuotaTable[data.tenants] : 0;
    let usage = data.opening && data.current ? data.current - data.opening : 0;
    usage *= (1.02264 * 40 / 3.6);

    return (
        <section className="form-contact">
            <div className="bedroom-select">
                <h4>Gas</h4>
                <p>Your allowance as allocated in the tenancy agreement is set out in kWh. Gas meters measure in m3 (metric) or f3 (imperial), therefore a conversion needs to be made from the readings in m3 or f3 into kWh. <a href="/files/upload-3793c6.pdf">Click here</a> to see the different types of gas meters which will help you to identify which one you have and details on the how the usage is calculated into kWh. <strong>If your meter measures in f3, you will need to contact the office in order for us to make your calculation, you must not use the calculator below.</strong></p>
                <p>Please note: The calculations used to generate gas bills are prescribed in The Gas (Calculation of Thermal Energy) Regulations 1996 (SI 1996/439). The actual method used may vary slightly depending on each individual energy supplier, however the method will be very similar to the calculations used to estimate your usage to date. Click here to view the calculation method as detailed on <a href="https://www.gov.uk/guidance/gas-meter-readings-and-bill-calculation" target="_blank">gov.uk</a>.</p>
            </div>
            <form>
                <div className="fields">
                    <div className="form-group">
                        <label>Number of tenants *</label>
                        <div className="input-control">
                            <select value={data.tenants} onChange={updateTenants}>
                                <option value="" key={null}>Please select...</option>
                                {[...Array(10).keys()].map((i) => <option value={i + 1} key={i + 1}>{i + 1}</option>)}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="fields cols-2">
                    <div className="form-group">
                        <label>Opening reading (m3) *</label>
                        <div className="input-control">
                            <input type="number" required={true} min="0" step="any" value={data.opening} onChange={updateOpening} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Current reading (m3) *</label>
                        <div className="input-control">
                            <input type="number" required={true} min="0" step="any" value={data.current} onChange={updateCurrent} />
                        </div>
                        {
                            data.opening && data.current && data.opening >= data.current
                                ? <span className="field-validation field-validation-error">The current reading should be higher than the opening reading.</span>
                                : null
                        }
                    </div>
                </div>
            </form>
            {
                data.tenants
                    && data.opening
                    && data.current
                    && (data.opening < data.current)
                    ?
                    <div className="bedroom-select">
                        <p className="quota-percentage">
                            <strong>
                                {(usage / quota).toLocaleString("en-GB", { style: "percent", maximumFractionDigits: 1 })}
                            </strong> of allowance used
                        </p>
                        <div className="fields cols-2 quota-summary">
                            <div className="form-group">
                                <label>Usage since opening reading:</label>
                                <p className="quota-value">{usage.toLocaleString("en-GB", { maximumFractionDigits: 2 })} kWh</p>
                            </div>
                            <div className="form-group">
                                <label>Total allowance:</label>
                                <p className="quota-value">{quota} kWh</p>
                            </div>
                        </div>
                        <h5>Important points to consider about your usage</h5>
                        <p>Your usage will fluctuate depending on the time of year, therefore it is not expected that you will use an equal amount of units per month. Naturally your gas usage will increase quicker in colder months but usually evens out when the warmer weather is here. The allowance is generous enough to ensure you can use the facilities at your convenience and that you will be comfortable in the property. You should be warm but not wasteful. Follow the guidance on <a href="/blog/2022/09/utilities">this link</a> for tips on how to be energy efficient. Please note comments on the blog are generic tips and you should apply them to you where applicable as best as you can.</p>
                        <p>Some properties will benefit from appliances which are there for your convenience and are energy efficient appliances but will naturally use a lot of your allowance, such as tumble dryers. Try to use this only when necessary, where there is a washing line use this as much as you can and consider using an indoor airier placed near the radiator (don't hang washing on the radiator, this won't keep you or the house very warm and is very inefficient way of drying clothes and heating your property).</p>
                        <p>Any overuse may not be apparent until all the bills for your tenancy have been received. If you exceed your allowance as noted in the tenancy agreement you will be required to pay the difference.</p>
                        <p>If you have any questions at all or need further guidance, please feel free to contact our office and we will do everything you can to help you.</p>
                    </div>
                    : null
            }
        </section>
    );
}

export default GasCalculatorStep;