import * as React from "react";
import { useState } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { PropertyInformation } from "./types";

type SelectOtherPropertiesState = {
    loaded: boolean;
    error: boolean;
    showDropdown: boolean;
    searchQuery: string | null;
    properties: PropertyInformationJson[];
};

type AddressJson = {
    lineOne: string;
    lineTwo: string | null;
    town: string;
    county: string;
    postalCode: string;
}

type PropertyInformationJson = {
    url: string;
    imageUrl: string;
    name: string;
    bedrooms: number;
    address: AddressJson;
};

const SelectOtherPropertiesStep: React.FunctionComponent<{ property: PropertyInformation, additionalProperties: PropertyInformation[], updateAdditionalProperty: (properties: PropertyInformation[]) => void; } & Partial<StepWizardChildProps>> = (props) =>
{
    const [data, setData] = useState<SelectOtherPropertiesState>({
        loaded: false,
        error: false,
        showDropdown: false,
        searchQuery: null,
        properties: [],
    });

    function advanceStep()
    {
        if (props.nextStep)
        {
            props.nextStep();
        }
    }

    function showDropdown()
    {
        setData({ loaded: data.loaded, error: data.error, showDropdown: true, searchQuery: data.searchQuery, properties: data.properties });
    }

    function hideDropdown()
    {
        setData({ loaded: data.loaded, error: data.error, showDropdown: false, searchQuery: data.searchQuery, properties: data.properties });
    }

    function changeQuery(query: string)
    {
        setData({ loaded: data.loaded, error: data.error, showDropdown: true, searchQuery: query, properties: data.properties });
    }

    function removeAdditionalProperty(propertyUrl: string)
    {
        props.updateAdditionalProperty(props.additionalProperties.filter(e => e.Url !== propertyUrl));
    }

    function addAdditionalProperty(property: PropertyInformationJson)
    {
        const properties = props.additionalProperties;
        properties.push({
            Name: property.name,
            Url: property.url,
            ImageUrl: property.imageUrl,
            Bedrooms: property.bedrooms,
            Address: {
                LineOne: property.address.lineOne,
                LineTwo: property.address.lineTwo,
                Town: property.address.town,
                County: property.address.county,
                PostalCode: property.address.postalCode
            }
        });
        props.updateAdditionalProperty(properties);
        hideDropdown();
    }

    const url = `/property/${props.property.Url}/arrange-viewing/alternate-properties`;
    if (!data.loaded)
    {
        fetch(url)
            .then(response =>
            {
                response.json()
                    .then((json: PropertyInformationJson[]) =>
                    {
                        setData({ loaded: true, error: false, showDropdown: data.showDropdown, searchQuery: null, properties: json });
                    })
                    .catch(() =>
                    {
                        setData({ loaded: true, error: true, showDropdown: false, searchQuery: null, properties: [] });
                    });
            })
            .catch(() =>
            {
                setData({ loaded: true, error: true, showDropdown: false, searchQuery: null, properties: [] });
            })
    }

    const selectedUrls = props.additionalProperties.map(e => e.Url);
    let dropdownProperties = data.properties.filter(e => selectedUrls.indexOf(e.url) === -1);
    if (data.searchQuery && data.searchQuery.length > 0)
    {
        const query = data.searchQuery.toLowerCase();
        dropdownProperties = dropdownProperties.filter(e =>
            e.name.toLowerCase().indexOf(query) !== -1
            || e.address.lineOne.toLowerCase().indexOf(query) !== -1
            || e.address.postalCode.toLowerCase().indexOf(query) !== -1);
    }

    if (dropdownProperties.length > 5)
    {
        dropdownProperties = dropdownProperties.slice(0, 8);
    }

    const emptyDropdown = dropdownProperties.length === 0;

    return (
        <section>
            <div className="bedroom-select">
                <h4>Choose properties</h4>
                <p>You can select an additional two properties</p>
                {
                    data.loaded
                        ? data.error
                            ?
                            <div className="alert alert-error text-center">There was an error retrieving alternative properties.</div>
                            : props.additionalProperties.length >= 2
                                ? null
                                :
                                <div className="form-contact">
                                    <div className="fields">
                                        <div className="property-dropdown-trap" style={{ display: data.showDropdown ? "block" : "none" }} onClick={() => hideDropdown()}></div>
                                        <div className="form-group" onFocus={() => showDropdown()}>
                                            <label style={{ textAlign: "left" }}>Select from dropdown or type preferred address /postcode e.g LN1 / LN5 *</label>
                                            <div className="input-control property-dropdown-wrapper">
                                                <input type="text" placeholder={`Search for more properties with ${props.property.Bedrooms} bedrooms`} onChange={(ev) => changeQuery(ev.currentTarget.value)} value={data.searchQuery ?? ""} />
                                                <div className="property-dropdown" style={{ display: data.showDropdown ? "block" : "none" }}>
                                                    {
                                                        emptyDropdown
                                                            ? <div className="property-dropdown-empty">There are no matching properties for your search.</div>
                                                            : dropdownProperties.map(e =>
                                                                <div className="property-dropdown-item" key={`property-dropdown-${e.url}`} onClick={() => addAdditionalProperty(e)}>
                                                                    <img src={e.imageUrl} alt={e.name} />
                                                                    <div>
                                                                        <h5>{e.name}</h5>
                                                                        <p>{e.address.lineOne}, {e.address.town} {e.address.postalCode}</p>
                                                                    </div>
                                                                    <i className="fa fa-plus" aria-hidden="true"></i>
                                                                </div>
                                                            )
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        : <div className="text-center"><i className="fa fa-spinner fa-spin fa-fw"></i> Loading alternative properties...</div>
                }
            </div>
            <div className="viewing-properties flex-columns">
                <div className="viewing-property column">
                    <img src={props.property.ImageUrl} alt={props.property.Name} className="column-image" />
                    <h5>{props.property.Name}</h5>
                    <p>{props.property.Address.LineOne}, {props.property.Address.Town} {props.property.Address.PostalCode}</p>
                </div>
                {
                    props.additionalProperties.map(p =>
                        <div className="viewing-property column" key={`additional-property-${p.Url}`}>
                            <button type="button" className="property-remove" onClick={() => removeAdditionalProperty(p.Url)}>&times;</button>
                            <img src={p.ImageUrl} alt={p.Name} className="column-image" />
                            <h5>{p.Name}</h5>
                            <p>{p.Address.LineOne}, {p.Address.Town} {p.Address.PostalCode}</p>
                        </div>)
                }
            </div>
            <fieldset className="text-center">
                <button type="submit" className="btn btn-primary" onClick={() => advanceStep()}>
                    Submit <i className="fa fa-plus" aria-hidden="true"></i>
                </button>
            </fieldset>
        </section>
    );
}

export default SelectOtherPropertiesStep;