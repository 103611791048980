import * as React from "react";
import { StepWizardChildProps } from "react-step-wizard";

const StepWizardNavigation: React.FunctionComponent<Partial<StepWizardChildProps>> = (props) =>
{
    if (!props.totalSteps || !props.currentStep)
    {
        return null;
    }

    function navigateToPreviousStep(step: number)
    {
        if (props.currentStep && props.goToStep && step < props.currentStep)
        {
            props.goToStep(step);
        }
    }

    const navItems: JSX.Element[] = [];
    for (let i = 1; i <= props.totalSteps; i++)
    {
        navItems.push(<li key={`wizard-nav-item-${i}`} className={(i === props.currentStep ? "active" : "")} onClick={() => navigateToPreviousStep(i)}>{i}</li>)
    }

    return (
        <ul className="booking-nav" children={navItems} />
    );
}

export default StepWizardNavigation;