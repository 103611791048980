import * as React from "react";
import StepWizard from "react-step-wizard";
import "./index.scss";
import StepWizardNavigation from "../StepWizardNavigation";
import SelectTypeStep from "./select-type";
import SelectOtherPropertiesStep from "./select-other-properties";
import SelectDateStep from "./select-date";
import SelectTimeStep from "./select-time";
import DetailsFormStep from "./details-form";
import { PropertyInformation, TimeBlockItem, ArrangeBookingState } from "./types";

export type ArrangeBookingProps = {
    property: PropertyInformation;
    token: string;
    blockedDates: string[];
    minDate: string;
};

export class ArrangeBooking extends React.Component<ArrangeBookingProps, ArrangeBookingState>
{
    constructor(props: ArrangeBookingProps)
    {
        super(props);

        this.state = {
            type: props.property.Bedrooms === 0 ? "help-me-choose" : null,
            bedrooms: null,
            date: null,
            time: null,
            additionalProperties: []
        }
    }

    updateType(type: string)
    {
        this.setState({
            type
        });
    }

    updateBedrooms(bedrooms: number)
    {
        this.setState({
            bedrooms
        });
    }

    updateDate(date: Date)
    {
        this.setState({
            date
        })
    }

    updateTime(time: TimeBlockItem)
    {
        this.setState({
            time
        })
    }

    updateAdditionalProperties(properties: PropertyInformation[])
    {
        this.setState({
            additionalProperties: properties
        })
    }

    render()
    {
        const steps: JSX.Element[] = [];
        if (this.props.property.Bedrooms !== 0)
        {
            steps.push(<SelectTypeStep property={this.props.property} updateType={(type) => this.updateType(type)} />);
        }
        if (this.state.type === "this-property-and-similar")
        {
            steps.push(<SelectOtherPropertiesStep additionalProperties={this.state.additionalProperties} updateAdditionalProperty={(properties) => this.updateAdditionalProperties
                (properties)} property={this.props.property} />)
        }

        steps.push(<SelectDateStep blockedDates={this.props.blockedDates} minDate={this.props.minDate} type={this.state.type} bedrooms={this.state.bedrooms} updateBedrooms={(bedrooms) => this.updateBedrooms(bedrooms)} updateDate={(date) => this.updateDate(date)} />);
        steps.push(<SelectTimeStep updateTime={(time) => this.updateTime(time)} property={this.props.property} type={this.state.type} date={this.state.date} />);
        steps.push(<DetailsFormStep property={this.props.property} token={this.props.token} options={this.state} />);

        return (
            <React.StrictMode>
                <StepWizard onStepChange={() => window.scrollTo({ top: document.querySelector("[arrange-booking]")?.clientTop })} children={steps} nav={<StepWizardNavigation />} />
            </React.StrictMode>
        );
    }
}