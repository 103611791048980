let check: HTMLInputElement | null = null;
let source: string | null = null;
let target: string | null = null;

function cloneField(ev: Event)
{
    if (!check || !source || !target)
    {
        return;
    }

    const sourceElem = ev.currentTarget as HTMLInputElement;
    const targetElem = document.querySelector(`input[name=${sourceElem.name.replace(source, target)}]`) as HTMLInputElement | null;
    if (!targetElem || check.checked)
    {
        return;
    }

    targetElem.value = sourceElem.value;
}

function configureFields(ev: Event | null)
{
    if (!check || !source || !target)
    {
        return;
    }

    const targetElems: NodeListOf<HTMLInputElement> = document.querySelectorAll(`input[name^=${target}]`);
    for (const elem of targetElems)
    {
        elem.readOnly = !check.checked;
        if (ev != null && ev.type === "change")
        {
            if (check.checked)
            {
            elem.value = "";
            }
            else
            {
                const sourceElem = document.querySelector(`input[name=${elem.name.replace(target, source)}]`) as HTMLInputElement | null;
                if (sourceElem)
                {
                    elem.value = sourceElem.value;
                }
            }
        }
    }

    const fieldset = document.querySelector("fieldset#billing_address") as HTMLFieldSetElement | null;
    if (fieldset)
    {
        fieldset.style.display = check.checked ? "block" : "none";
    }
}

function SetupAddressFields()
{
    check = document.getElementById("enter_billing_address") as HTMLInputElement | null;
    if (!check || !check.dataset.dbsSource || !check.dataset.dbsTarget)
    {
        return;
    }

    source = check.dataset.dbsSource;
    target = check.dataset.dbsTarget;

    check.addEventListener("change", configureFields);
    configureFields(null);

    const sourceElements: NodeListOf<HTMLInputElement> = document.querySelectorAll(`input[name^=${source}]`);
    for (const elem of sourceElements)
    {
        const targetElem = document.querySelector(`input[name=${elem.name.replace(source, check.dataset.dbsTarget)}]`) as HTMLInputElement | null;
        if (!targetElem)
        {
            continue;
        }

        console.log(`setup ${targetElem.name}`);
        elem.addEventListener("change", cloneField);
    }
}

window.addEventListener("load", SetupAddressFields)