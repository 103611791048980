import * as React from "react";
import { useState } from "react";


type ElectricCalculatorState = {
    rate: "single" | "multi" | undefined;
    tenants: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | undefined;
    opening: number | undefined;
    current: number | undefined;
    opening2: number | undefined;
    current2: number | undefined;
};

const QuotaTable = {
    1: 3000,
    2: 3400,
    3: 4000,
    4: 5000,
    5: 6000,
    6: 7000,
    7: 8000,
    8: 9000,
    9: 10000,
    10: 11000
}

const ElectricCalculatorStep: React.FunctionComponent = () =>
{
    const [data, setData] = useState<ElectricCalculatorState>({
        rate: undefined,
        tenants: undefined,
        opening: undefined,
        current: undefined,
        opening2: undefined,
        current2: undefined
    });

    function updateRate(ev: React.ChangeEvent<HTMLSelectElement>)
    {
        setData({
            rate: ev.target.value === "single" || ev.target.value === "multi" ? ev.target.value : undefined,
            tenants: data.tenants,
            opening: data.opening,
            current: data.current,
            opening2: ev.target.value === "multi" ? data.opening2 : undefined,
            current2: ev.target.value === "multi" ? data.current2 : undefined
        });
    }

    function updateTenants(ev: React.ChangeEvent<HTMLSelectElement>)
    {
        const result = parseInt(ev.target.value);
        setData({
            rate: data.rate,
            tenants: result > 0 && result <= 10 ? result as (1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10) : undefined,
            opening: data.opening,
            current: data.current,
            opening2: data.opening2,
            current2: data.current2
        });
    }

    function updateOpening(ev: React.ChangeEvent<HTMLInputElement>)
    {
        const result = parseInt(ev.target.value);
        setData({
            rate: data.rate,
            tenants: data.tenants,
            opening: result > 0 ? result : undefined,
            current: data.current,
            opening2: data.opening2,
            current2: data.current2
        });
    }

    function updateCurrent(ev: React.ChangeEvent<HTMLInputElement>)
    {
        const result = parseInt(ev.target.value);
        setData({
            rate: data.rate,
            tenants: data.tenants,
            opening: data.opening,
            current: result > 0 ? result : undefined,
            opening2: data.opening2,
            current2: data.current2
        });
    }

    function updateOpening2(ev: React.ChangeEvent<HTMLInputElement>)
    {
        const result = parseInt(ev.target.value);
        setData({
            rate: data.rate,
            tenants: data.tenants,
            opening: data.opening,
            current: data.current,
            opening2: result > 0 ? result : undefined,
            current2: data.current2
        });
    }

    function updateCurrent2(ev: React.ChangeEvent<HTMLInputElement>)
    {
        const result = parseInt(ev.target.value);
        setData({
            rate: data.rate,
            tenants: data.tenants,
            opening: data.opening,
            current: data.current,
            opening2: data.opening2,
            current2: result > 0 ? result : undefined
        });
    }

    const quota = data.tenants ? QuotaTable[data.tenants] : 0;
    let usage = data.opening && data.current ? data.current - data.opening : 0;
    if (data.rate === "multi" && data.opening2 && data.current2)
    {
        usage += (data.current2 - data.opening2);
    }

    return (
        <section className="form-contact">
            <div className="bedroom-select">
                <h4>Electric</h4>
                <p>Your allowance as allocated in the tenancy agreement is set out in kWh. Electric meters measure in kWh so to calculate your electric use you will need to ensure you have the kWh readings from your meter (taken from the meters on the day you check) and your opening readings as detailed in the inventory when you moved in and enter them below. Some meters have more than one rate (multi rate) and you will need to add these rates together.</p>
                <p><a href="/files/upload-06e68b.pdf">Click here</a> to see the different types of electric meters which will help you to identify which one you have.</p>
            </div>
            <form>
                <div className="fields">
                    <div className="form-group">
                        <label>single rate / multi rate *</label>
                        <div className="input-control">
                            <select value={data.rate} onChange={updateRate}>
                                <option value="" key={null}>Please select...</option>
                                <option value="single" key="imperial">Single rate</option>
                                <option value="multi" key="metric">Multi rate</option>
                            </select>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Number of tenants *</label>
                        <div className="input-control">
                            <select value={data.tenants} onChange={updateTenants}>
                                <option value="" key={null}>Please select...</option>
                                {[...Array(10).keys()].map((i) => <option value={i + 1} key={i + 1}>{i + 1}</option>)}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="fields cols-2">
                    <div className="form-group">
                        <label>Opening reading {data.rate === "multi" ? "- rate 1" : ""} (kWh) *</label>
                        <div className="input-control">
                            <input type="number" required={true} min="0" step="any" value={data.opening} onChange={updateOpening} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Current reading {data.rate === "multi" ? "- rate 1" : ""} (kWh) *</label>
                        <div className="input-control">
                            <input type="number" required={true} min="0" step="any" value={data.current} onChange={updateCurrent} />
                        </div>
                        {
                            data.opening && data.current && data.opening >= data.current
                                ? <span className="field-validation field-validation-error">The current reading should be higher than the opening reading.</span>
                                : null
                        }
                    </div>
                </div>
                {
                    data.rate === "multi"
                        ?
                        <div className="fields cols-2">
                            <div className="form-group">
                                <label>Opening reading - rate 2 (kWh) *</label>
                                <div className="input-control">
                                    <input type="number" required={true} min="0" step="any" value={data.opening2} onChange={updateOpening2} />
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Current reading - rate 2 (kWh) *</label>
                                <div className="input-control">
                                    <input type="number" required={true} min="0" step="any" value={data.current2} onChange={updateCurrent2} />
                                </div>
                                {
                                    data.opening2 && data.current2 && data.opening2 >= data.current2
                                        ? <span className="field-validation field-validation-error">The current reading should be higher than the opening reading.</span>
                                        : null
                                }
                            </div>
                        </div>
                        : null
                }
            </form>
            {
                data.rate
                    && data.tenants
                    && data.opening
                    && data.current
                    && (data.opening < data.current)
                    && (data.rate === "single" || (data.opening2 && data.current2 && (data.opening2 < data.current2)))
                    ?
                    <div className="bedroom-select">
                        <p className="quota-percentage">
                            <strong>
                                {(usage / quota).toLocaleString("en-GB", { style: "percent", maximumFractionDigits: 1 })}
                            </strong> of allowance used
                        </p>
                        <div className="fields cols-2 quota-summary">
                            <div className="form-group">
                                <label>Usage since opening reading:</label>
                                <p className="quota-value">{usage.toLocaleString("en-GB", { maximumFractionDigits: 2 })} kWh</p>
                            </div>
                            <div className="form-group">
                                <label>Total allowance:</label>
                                <p className="quota-value">{quota} kWh</p>
                            </div>
                        </div>
                        <h5>Important points to consider about your usage</h5>
                        <p>Your usage will fluctuate depending on the time of year, therefore it is not expected that you will use an equal amount of units per month. Naturally your gas usage will increase quicker in colder months but usually evens out when the warmer weather is here. The allowance is generous enough to ensure you can use the facilities at your convenience and that you will be comfortable in the property. You should be warm but not wasteful. Follow the guidance on <a href="/blog/2022/09/utilities">this link</a> for tips on how to be energy efficient. Please note comments on the blog are generic tips and you should apply them to you where applicable as best as you can.</p>
                        <p>Some properties will benefit from appliances which are there for your convenience and are energy efficient appliances but will naturally use a lot of your allowance, such as tumble dryers. Try to use this only when necessary, where there is a washing line use this as much as you can and consider using an indoor airier placed near the radiator (don't hang washing on the radiator, this won't keep you or the house very warm and is very inefficient way of drying clothes and heating your property).</p>
                        <p>Any overuse may not be apparent until all the bills for your tenancy have been received. If you exceed your allowance as noted in the tenancy agreement you will be required to pay the difference.</p>
                        <p>If you have any questions at all or need further guidance, please feel free to contact our office and we will do everything you can to help you.</p>
                    </div>
                    : null
            }
        </section>
    );
}

export default ElectricCalculatorStep;