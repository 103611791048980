import * as React from "react";
import StepWizard from "react-step-wizard";
import UtilitySelection from "./utility-selection";
import GasCalculatorStep from "./gas-calculator";
import ElectricCalculatorStep from "./electric-calculator";
import WaterCalculatorStep from "./water-calculator";
import StepWizardNavigation from "../StepWizardNavigation";

import "../ArrangeBooking/index.scss";

type UtilitiesCalculatorState = {
    type: string | null;
};

export type UtilitiesCalculatorProps = {
};

export class UtilitiesCalculator extends React.Component<UtilitiesCalculatorProps, UtilitiesCalculatorState>
{
    constructor(props: UtilitiesCalculatorProps)
    {
        super(props);
        this.state = {
            type : null
        };
    }

    updateType(type: string)
    {
        this.setState({
            type
        });
    }

    render()
    {
        const steps: JSX.Element[] = [];

        steps.push(<UtilitySelection updateType={(type) => this.updateType(type)} />);
        switch (this.state.type)
        {
            case "gas":
                steps.push(<GasCalculatorStep />);
                break;
            case "electric":
                steps.push(<ElectricCalculatorStep />);
                break;
            case "water":
                steps.push(<WaterCalculatorStep />);
                break;
            default:
                steps.push(<section></section>)
                break;
        }

        return (
            <StepWizard onStepChange={() => window.scrollTo({ top: document.querySelector("[utilities-calculator]")?.clientTop })} children={steps} nav={<StepWizardNavigation />} />
        );
    }
}