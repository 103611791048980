import * as React from "react";
import { StepWizardChildProps } from "react-step-wizard";

const UtilitySelectionStep: React.FunctionComponent<{ updateType: (type: string) => void } & Partial<StepWizardChildProps>> = (props) =>
{
    function changeType(type: string)
    {
        props.updateType(type);
        if (props.nextStep)
        {
            props.nextStep();
        }
    }

    return (
        <section>
            <div className="bedroom-select">
                <h4>Please choose your utility</h4>
                <p>To ensure you remain within your utility allowance, you will need to monitor your use during the tenancy term. The allowance is not there to trick you or set you up to fail; if you are sensible and conscious of your usage you should have no problem staying within the allowance.</p>
                <p>Before you start, you will need to obtain your opening readings from the start of the tenancy (found on your inventory) and also take current meter readings. If you live in accommodation where you cannot access the meters as they are located in a locked cupboard, please contact us and we will help you to obtain these.</p>
            </div>
            <div className="flex-columns">
                <label onClick={() => changeType("gas")}>
                    <div className="column">
                        <div className="column-image">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 126.298 200">
                                <path d="M143.8,224.142c6.7-11.283,10.681-22.744,11.522-35.211.717-10.712-2.338-20.343-8-29.331-6.12-9.7-14.452-17.1-23.9-23.4-1.018-.686-2.171-1.174-3.2-1.849-1.558-1.029-1.787-.27-1.725,1.143a34.556,34.556,0,0,1-1.787,12.967c-2.005,5.881-6.078,10.276-10.348,14.546-3.159,3.148-6.483,6.151-8.966,9.933-3.668,5.579-3.761,11.751-3.19,18.058a76.138,76.138,0,0,0,10.889,32.333c.187.312.291.665.592,1.351a120.906,120.906,0,0,1-42.1-71.836c-1.642-9.382-2.67-18.878-1.579-28.5,1.1-9.725,5.34-17.9,11.512-25.237,5.673-6.743,12.364-12.489,18.39-18.889a113.773,113.773,0,0,0,10.276-12.946c7.626-10.889,9.777-23.014,9.756-35.845,0-1.694-.208-3.377-.343-5.07-.114-1.455-.052-2.255,1.766-1.184,4.842,2.847,9.652,5.714,14.3,8.894a162.183,162.183,0,0,1,36.2,33.695A115.831,115.831,0,0,1,183.548,104.1a93.219,93.219,0,0,1,3.886,37.217A121.2,121.2,0,0,1,173.065,189.2a122.258,122.258,0,0,1-23.647,30.557c-1.34,1.268-2.795,2.41-4.208,3.605a13.654,13.654,0,0,1-1.392.79Z" transform="translate(-61.556 -24.682)" fill="#f26963" />
                            </svg>
                        </div>
                        <div className="column-heading">
                            <h4>Gas</h4>
                        </div>
                    </div>
                </label>
                <label onClick={() => changeType("electric")}>
                    <div className="column">
                        <div className="column-image">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 115.147 200">
                                <path d="M68.264,136.832q45.205-55.492,90.4-110.995l.6.252c-.745,4.383-1.468,8.766-2.254,13.139q-2.58,14.424-5.222,28.826c-1.709,9.238-3.565,18.445-5.18,27.694-.923,5.274-1.772,10.559-2.968,15.781-.419,1.856.252,2.611,2.3,2.59,10.905-.094,21.8-.052,32.706-.052h4.771C152.8,151.638,122.582,188.738,92.35,225.838c.084-5.044,1.164-9.752,2.034-14.5,2.16-11.828,4.215-23.667,6.376-35.5,1.73-9.458,3.565-18.906,5.327-28.365.524-2.821.933-5.673,1.468-8.494.3-1.636-.724-1.594-1.783-1.594q-6.653,0-13.317.01H68.642l-.377-.566Z" transform="translate(-68.264 -25.838)" fill="#fdbb1d" />
                            </svg>
                        </div>
                        <div className="column-heading">
                            <h4>Electric</h4>
                        </div>
                    </div>
                </label>
                <label onClick={() => changeType("water")}>
                    <div className="column">
                        <div className="column-image">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.551 200">
                                <path d="M182.551,185.465c-7.715,16.571-19.132,28.206-35.455,34.6a60.513,60.513,0,0,1-37.757,2.447,62.1,62.1,0,0,1-30.27-17.525,60.333,60.333,0,0,1-16.208-31.006,63.13,63.13,0,0,1,6.823-43.242c5.558-10.162,10.6-20.615,15.887-30.944,7.383-14.425,14.808-28.818,22.15-43.263,3.184-6.263,6.212-12.61,9.416-18.863,2.033-3.972,4.418-7.788,6.015-11.977.695-1.815,1.317-1.473,2.3-.114a29.065,29.065,0,0,1,2.613,4.832c4.438,9.592,9.509,18.863,14.321,28.268,2.686,5.247,5.309,10.525,7.974,15.773,3.941,7.746,7.7,15.586,11.9,23.187,3.412,6.191,6.388,12.61,9.882,18.769s6.409,12.662,9.789,18.894a16.394,16.394,0,0,1,1.193,3.567c.809,2.8,1.68,5.579,2.468,8.389,1.7,6.118,2.084,16.6,1.006,23.052a69.868,69.868,0,0,1-2.053,9.208c-.705,2.271-1.524,4.511-2.012,5.952ZM84.566,158.182c-1.638-.353-2.219.093-2.312,1.566-1.193,18.832,12.309,35.361,30.985,38.825,1.856.342,2.675-.311,2.738-2.261A42,42,0,0,1,116.7,189c.456-2.126-.207-2.821-2.219-3.215a23.1,23.1,0,0,1-11.853-6.17,24.408,24.408,0,0,1-7.86-18.49c0-.964.218-2.043-1.338-2.157-3.059-.218-6.118-.539-8.866-.788Z" transform="translate(-61.66 -24.448)" fill="#66cceb" />
                            </svg>
                        </div>
                        <div className="column-heading">
                            <h4>Water</h4>
                        </div>
                    </div>
                </label>
            </div>
        </section>
    );
}

export default UtilitySelectionStep;