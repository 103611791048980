import * as React from "react";
import { StepWizardChildProps } from "react-step-wizard";
import Calendar, { CalendarTileProperties } from 'react-calendar';
import { formatDate } from "./types";


const SelectDateStep: React.FunctionComponent<{ blockedDates: string[], minDate: string, type: string | null, bedrooms: number | null, updateBedrooms: (bedrooms: number) => void, updateDate: (date: Date) => void } & Partial<StepWizardChildProps>> = (props) =>
{
    const blocked = props.blockedDates;

    function changeBedrooms(bedrooms: number)
    {
        props.updateBedrooms(bedrooms);
    }

    function changeDate(date: Date)
    {
        props.updateDate(date);
        if (props.nextStep)
        {
            props.nextStep();
        }
    }

    function isDateDisabled(props: CalendarTileProperties)
    {
        const day = props.date.getDay();
        const dateString = formatDate(props.date);
        return blocked.indexOf(dateString) !== -1 || day == 0 || day == 6; // SAT, SUN always disabled.
    }

    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const fallbackMin = new Date(today.valueOf());
    fallbackMin.setDate(fallbackMin.getDate() + 3);
    const minParts = props.minDate.split('-');
    const min = minParts.length === 3
        ? new Date(parseInt(minParts[0]), parseInt(minParts[1]) - 1, parseInt(minParts[2]))
        : fallbackMin;
    const max = new Date(min.valueOf());
    max.setDate(min.getDate() + 90);

    return (
        <section>
            {
                props.type === "help-me-choose"
                    ? (
                        <div className="bedroom-select">
                            <h4>Need help with choosing a property?</h4>
                            <p>If you are looking for a property but not sure where to start, we are here to help. Simply choose how many bedrooms you want and select a convenient time for viewings and we will organise the rest. You are one step closer to finding your perfect property.</p>
                            <p>Click a date and time to suit you. If you're looking as a group, it’s a good idea to have all or most of the group with you.</p>
                            <h4>How many bedrooms?</h4>
                            <ul>
                                <li className={props.bedrooms === 1 ? "active" : ""} onClick={() => changeBedrooms(1)}>1</li>
                                <li className={props.bedrooms === 2 ? "active" : ""} onClick={() => changeBedrooms(2)}>2</li>
                                <li className={props.bedrooms === 3 ? "active" : ""} onClick={() => changeBedrooms(3)}>3</li>
                                <li className={props.bedrooms === 4 ? "active" : ""} onClick={() => changeBedrooms(4)}>4</li>
                                <li className={props.bedrooms === 5 ? "active" : ""} onClick={() => changeBedrooms(5)}>5</li>
                                <li className={props.bedrooms === 6 ? "active" : ""} onClick={() => changeBedrooms(6)}>6</li>
                                <li className={props.bedrooms === 7 ? "active" : ""} onClick={() => changeBedrooms(7)}>7+</li>
                                <li className={props.bedrooms === -1 ? "active" : ""} onClick={() => changeBedrooms(-1)}>Any</li>
                            </ul>
                        </div>
                    )
                    : (
                        <div className="bedroom-select">
                            <p>Click a date and time to suit you. If you're looking as a group, it’s a good idea to have all or most of the group with you.</p>
                        </div>
                    )
            }
            {
                props.type !== "help-me-choose" || props.bedrooms
                    ? <Calendar minDate={min} maxDate={max} tileDisabled={(props) => isDateDisabled(props)} onChange={(value: Date) => changeDate(value)} />
                    : null
            }
        </section>
    );
}

export default SelectDateStep;