import * as React from "react";
import { useState } from "react";
import { StepWizardChildProps } from "react-step-wizard";
import { PropertyInformation, TimeBlockItem, formatDate } from "./types";

type SelectTimeResult = {
    AM: TimeBlockItem[];
    PM: TimeBlockItem[];
};

type SelectTimeState = {
    loaded: boolean;
    error: boolean;
    currentType: string | null;
    currentDate: string | null;
    selectedGroup: 'AM' | 'PM';
} & SelectTimeResult;

const SelectTimeStep: React.FunctionComponent<{ property: PropertyInformation, type: string | null, date: Date | null, updateTime: (time: TimeBlockItem) => void } & Partial<StepWizardChildProps>> = (props) =>
{
    if (!props.type || !props.date)
    {
        return null;
    }

    const [data, setData] = useState<SelectTimeState>({
        loaded: false,
        error: false,
        AM: [],
        PM: [],
        currentType: null,
        currentDate: null,
        selectedGroup: 'AM'
    });

    function changeTime(time: TimeBlockItem)
    {
        props.updateTime(time);
        if (props.nextStep)
        {
            props.nextStep();
        }
    }

    function changeTimeGroup(group: 'AM' | 'PM')
    {
        setData({ loaded: data.loaded, error: data.error, AM: data.AM, PM: data.PM, currentType: data.currentType, currentDate: data.currentDate, selectedGroup: group });
    }

    const date = formatDate(props.date);
    if ((data.currentType !== props.type || data.currentDate !== date) && !data.error)
    {
        const url = props.property.Bedrooms === 0
            ? `/arrange-viewing/availability/${props.type}/${date}`
            : `/property/${props.property.Url}/arrange-viewing/availability/${props.type}/${date}`;

        fetch(url)
            .then(response =>
            {
                response.json()
                    .then((json: SelectTimeResult) =>
                    {
                        setData({ loaded: true, error: false, AM: json.AM, PM: json.PM, currentType: props.type, currentDate: date, selectedGroup: 'AM' });
                    })
                    .catch(() =>
                    {
                        setData({ loaded: data.loaded, error: true, AM: data.AM, PM: data.PM, currentType: null, currentDate: null, selectedGroup: 'AM' });
                    });
            })
            .catch(() =>
            {
                setData({ loaded: data.loaded, error: true, AM: data.AM, PM: data.PM, currentType: null, currentDate: null, selectedGroup: 'AM' });
            });
    }

    if (data.error)
    {
        return (<section className="alert alert-error">There was an error loading the available times.</section>)
    }
    else if (data.loaded)
    {
        return (
            <section>
                <div className="time-selector">
                    <div className="time-header">
                        <h4>{props.date.toLocaleDateString('en-GB', { dateStyle: 'long' })}</h4>
                    </div>
                    <div className="time-group">
                        <button type="button" className={data.selectedGroup === 'AM' ? 'active' : ''} onClick={() => changeTimeGroup('AM')}>AM</button>
                        <button type="button" className={data.selectedGroup === 'PM' ? 'active' : ''} onClick={() => changeTimeGroup('PM')}>PM</button>
                    </div>
                    <div className="time-options">
                        {data.selectedGroup === 'AM'
                            ?
                            <ul>
                                {data.AM.map(e => <li key={`${e.start}:${e.end}`} onClick={() => changeTime(e)}>{e.start} - {e.end}</li>)}
                            </ul>
                            :
                            <ul>
                                {data.PM.map(e => <li key={`${e.start}:${e.end}`} onClick={() => changeTime(e)}>{e.start} - {e.end}</li>)}
                            </ul>
                        }
                    </div>
                    <p className="time-footer">If your desired time is not available, please <a href="/contact-us">contact us</a>.</p>
                </div>
            </section>
        );
    }
    else
    {
        return (<section className="text-center"><i className="fa fa-spinner fa-spin fa-fw"></i> Loading...</section>)
    }
}

export default SelectTimeStep;