const documentSliders: LatestSlider[] = [];

class LatestSlider
{
    private container: HTMLElement;
    private prev: HTMLElement | null;
    private next: HTMLElement | null;
    private large: HTMLImageElement | null = null;
    private speed: number;

    constructor(container: HTMLElement)
    {
        this.container = container;
        this.speed = this.container.dataset.speed
            ? parseInt(this.container.dataset.speed)
            : 8000;

        this.prev = this.container.parentElement?.querySelector(".nav-prev") as HTMLElement | null;
        if (this.prev)
        {
            this.prev.addEventListener("click", (ev) =>
            {
                ev.preventDefault();
                this.revertSlider();
            });
        }

        this.next = this.container.parentElement?.querySelector(".nav-next") as HTMLElement | null;
        if (this.next)
        {
            this.next.addEventListener("click", (ev) =>
            {
                ev.preventDefault();
                this.advanceSlider();
            });
        }

        if (this.container.dataset.target)
        {
            this.large = document.querySelector(this.container.dataset.target) as HTMLImageElement | null;
        }

        const newNodes: Node[] = [];
        for (let i = 0; i < this.container.childNodes.length; i++)
        {
            const child = this.container.childNodes[i];

            if (child.nodeType == Node.ELEMENT_NODE)
            {
                newNodes.push(child.cloneNode(true));
            }
            else
            {
                child.remove();
            }
        }

        if (this.speed > 0)
        {
            setInterval(() => this.advanceSlider(), this.speed);
        }
    }

    private advanceSlider()
    {
        this.container.classList.add("tick");

        if (this.large && this.container.childNodes.length >= 2)
        {
            const nextChild = this.container.childNodes.item(1) as HTMLElement;
            const nextImage = nextChild.querySelector("img");
            if (nextImage)
            {
                this.large.src = nextImage.src;
            }
        }

        setTimeout(() => this.cleanAdvanceSlider(), 500);
    }

    private cleanAdvanceSlider()
    {
        const first = this.container.childNodes.item(0);
        this.container.appendChild(first);

        this.container.classList.remove("tick");
    }

    private revertSlider()
    {
        const last = this.container.childNodes.item(this.container.childNodes.length - 1);
        if (this.large)
        {
            const lastImage = (last as HTMLElement).querySelector("img");
            if (lastImage)
            {
                this.large.src = lastImage.src;
            }
        }

        this.container.prepend(last);

        this.container.classList.add("tock");
        window.requestAnimationFrame(() => this.container.classList.add("ing"));
        setTimeout(() => this.cleanRevertSlider(), 500);
    }

    private cleanRevertSlider()
    {
        this.container.classList.remove("tock");
        this.container.classList.remove("ing");
    }
}

export function Initialize()
{
    const sliders = document.querySelectorAll("[dbs-carousel]");

    for (let i = 0; i < sliders.length; i++)
    {
        documentSliders.push(new LatestSlider(sliders[i] as HTMLElement));
    }
}