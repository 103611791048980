import '../css/main.scss';

import 'jquery-validation';
$.validator.unobtrusive = require('jquery-validation-unobtrusive');
import 'jssocials';

import * as React from "react";
import { createRoot } from "react-dom/client";
import { ArrangeBooking, ArrangeBookingProps } from "./components/ArrangeBooking";
import { PropertyInformation } from "./components/ArrangeBooking/types";
import { UtilitiesCalculator, UtilitiesCalculatorProps } from "./components/UtilitiesCalculator";

/* Bootstrap modules - comment out as required */
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/tab';
import 'bootstrap/js/dist/offcanvas';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

import 'lazysizes';

import '../../../Components/Base/BaseComponent/Content/validation/mustbetrue';

import '../../../Components/Base/BaseComponent/Content/Components/AjaxPage';
import { InitialiseForElement as AjaxFormInitialise } from '../../../Components/Base/BaseComponent/Content/Components/PartialHost';
import { Initialise as RecaptchaFormInitialise } from '../../../Components/Base/BaseComponent/Content/Components/RecaptchaForm';
import '../../../Components/Base/BaseComponent/Content/Components/StyleSwitcher.ts';
import './components/MapProperties';
import './components/BillingAddress';


import { Initialize as InitializeLatestSliders } from './components/LatestSlider';

document.body.classList.remove("no-js");
document.body.classList.add("js");

function loadArrangeBooking()
{
    const items = document.querySelectorAll("[arrange-booking]");

    for (const element of items)
    {
        const item = element as HTMLElement;

        if (!item.dataset.property || !item.dataset.token || !item.dataset.minDate)
        {
            return;
        }

        const property = JSON.parse(item.dataset.property) as PropertyInformation;
        const blockedDates = item.dataset.blocked ? JSON.parse(item.dataset.blocked) as string[] : [];
        const token = item.dataset.token;
        const minDate = item.dataset.minDate;

        const props: ArrangeBookingProps = {
            property,
            token,
            blockedDates,
            minDate
        };

        const root = createRoot(item);
        root.render(React.createElement(ArrangeBooking, props));
    }
}

function loadUtilitiesCalculator()
{
    const items = document.querySelectorAll("[utilities-calculator]");

    for (const element of items)
    {
        const item = element as HTMLElement;
        const props: UtilitiesCalculatorProps = {
        };

        const root = createRoot(item);
        root.render(React.createElement(UtilitiesCalculator, props));
    }
}

function loadFormSupport()
{
    const forms = document.querySelectorAll("form[data-ajax]");
    for (const element of forms)
    {
        if ((element as HTMLFormElement).dataset.recaptcha)
        {
            RecaptchaFormInitialise(element as HTMLFormElement);
        }
        else
        {
            AjaxFormInitialise(element as HTMLFormElement);
        }
    }
}

function loadSocials()
{
    jsSocials.shares.whatsapp.shareUrl = "https://wa.me/?text={url}%20{text}";
    const socialLinks = document.getElementsByClassName("item-social");
    for (const element of socialLinks)
    {
        const socialLink = element as HTMLDivElement;

        const config = {
            showLabel: false,
            showCount: false,
            url: socialLink.dataset["target"],
            text: socialLink.dataset["desc"],
            shares: [
                {
                    share: "facebook",
                    logo: "fa fa-facebook-f"
                },
                {
                    share: "twitter",
                    logo: "fa fa-twitter"
                },
                {
                    share: "whatsapp",
                    logo: "fa fa-whatsapp"
                },
                {
                    share: "email",
                    logo: "fa fa-at"
                }
            ]
        } as jsSocials.IConfig;

        $(socialLink).jsSocials(config);
    }
}

function loadReset()
{
    const resetButton = document.querySelector("button.action-reset") as HTMLButtonElement | null;
    if (!resetButton)
    {
        return;
    }

    resetButton.addEventListener("click", function (ev)
    {
        ev.preventDefault();

        if (!resetButton.form)
        {
            return;
        }

        const dropdowns = resetButton.form.querySelectorAll("select");
        for (let dropdown of dropdowns)
        {
            dropdown.selectedIndex = 0;
        }


        const texts = resetButton.form.querySelectorAll("input[type=text]");
        for (let text of texts)
        {
            (text as HTMLInputElement).value = "";
        }

        const showSold = resetButton.form.querySelector("input[value=true]#ShowSold") as HTMLInputElement | null;
        if (showSold)
        {
            showSold.checked = true;
        }
    });
}

function loadPopupModal()
{
    jQuery('#register-interest-modal').modal("show");
}

function manageDateFilterFields()
{
    const dates = document.querySelectorAll(".search-fields-container input[type=date]");
    for (const element of dates)
    {
        const date = element as HTMLInputElement;
        if (!date.value)
        {
            date.classList.add("empty");
        }

        date.addEventListener("change", () =>
        {
            if (date.value)
            {
                date.classList.remove("empty");
            }
            else
            {
                date.classList.add("empty");
            }
        });
    }
}

function loadDocument()
{
    loadFormSupport();
    InitializeLatestSliders();
    loadSocials();
    loadReset();
    loadArrangeBooking();
    loadUtilitiesCalculator();
    loadPopupModal();
    manageDateFilterFields();
}

document.addEventListener("DOMContentLoaded", loadDocument);